// src/components/FirstVisitModal.js
import React, { useState } from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  FormGroup, 
  FormControlLabel, 
  Checkbox,
  Typography,
  Box
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import config from '../../config';
import logo from '../../logo256.png';

const languages = config.dataNames[0].aiLanguages;

const FirstVisitModal = ({ open, onClose, onLanguageSelect }) => {
  const { t } = useTranslation();
  const [selectedLangs, setSelectedLangs] = useState([]);

  const handleLanguageChange = (langCode) => {
    setSelectedLangs(prev => 
      prev.includes(langCode) ? prev.filter(l => l !== langCode) : [...prev, langCode]
    );
  };

  const handleSubmit = () => {
    onLanguageSelect(selectedLangs);
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: 3,
          width: '100%',
          maxWidth: 400,
        }
      }}
    >
      <DialogTitle sx={{ textAlign: 'center', pb: 0 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <img src={logo} alt="Logo" style={{ height: 52, marginBottom: 8 }} />
          <Typography variant="h5" component="div" sx={{ fontWeight: 'bold', color: '#03487d' }}>
            {t('welcome')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
          {t('chooseSecondLanguage')}
        </Typography>
        <FormGroup>
          {languages.map((lang) => (
            <FormControlLabel
              key={lang.code}
              control={
                <Checkbox
                  checked={selectedLangs.includes(lang.code)}
                  onChange={() => handleLanguageChange(lang.code)}
                  sx={{ '&.Mui-checked': { color: '#03487d' } }}
                />
              }
              label={`${lang.word} (${lang.name})`}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', pb: 3 }}>
        <Button 
          onClick={handleSubmit} 
          variant="contained" 
          sx={{ 
            bgcolor: '#03487d', 
            '&:hover': { bgcolor: '#023e6d' },
            minWidth: 120
          }}
        >
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FirstVisitModal;