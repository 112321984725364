// src/components/SelectCategory.js
import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';

const SelectCategory = ({ categories, selectedCategories, onChange }) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="category-select-label">{t('selectCategory')}</InputLabel>
      <Select
        labelId="category-select-label"
        id="category-select"
        multiple
        value={selectedCategories}
        onChange={onChange}
        renderValue={(selected) => selected.length === 0 ? t('all') : selected.join(', ')}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200,
              width: 250,
            },
          },
        }}
      >
        {categories.map((category) => (
          <MenuItem key={category} value={category} size="small" style={{ padding: '4px 8px' }}>
            <Checkbox size="small" checked={selectedCategories.indexOf(category) > -1} />
            <ListItemText size="small" primary={category} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectCategory;