import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Container, Typography, Box, Alert, CircularProgress, Switch, FormControlLabel, Link, Grid, IconButton, Checkbox, useMediaQuery, useTheme } from '@mui/material';
import GetAppIcon from '@mui/icons-material/GetApp';
import SearchIcon from '@mui/icons-material/Search';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import HomeIcon from '@mui/icons-material/Home';
import { Print } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import SearchBar from '../components/SearchBar';
import SubjectsTable from '../components/SubjectsTable';
import LanguageSwitcher from '../components/LanguageSwitcher';
import TVModePanel from '../components/TVModePanel';
import TopicsGrid from '../components/TopicsGrid';
import TopSubjects from '../components/TopSubjects';
import './WelcomePage.css';
import Footer from '../components/Footer';
import config from '../config';
import QrCodeIcon from '@mui/icons-material/QrCode';
import QRCodeModal from './components/QRCodeModal';
import SelectCategory from './components/SelectCategory';
import FirstVisitModal from './components/FirstVisitModal';

import { 
  fetchLatestSubjects, 
  fetchSubjectsCount, 
  fetchTopics, 
  searchSubjectById,
  searchSubjects,
  searchSubjectsByKeywords,
} from '../services/apiService';

import mainLogo from '../profoundwords-logo192.png';
import logo from '../logo256.png';
import GoogleAd from '../components/GoogleAd';
import AiLangs2 from './AiLangs2';

const WelcomePage = () => {
  const { t, i18n } = useTranslation();
  const [latestSubjects, setLatestSubjects] = useState([]);
  const [topics, setTopics] = useState([]);
  const [searchResults, setSearchResults] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [subjectsCount, setSubjectsCount] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tvMode, setTvMode] = useState(true);
  const [videoIndex, setVideoIndex] = useState(0);
  const [searchType, setSearchType] = useState('prefix');
  const [searchWords, setSearchWords] = useState('');
  const [qrCodeOpen, setQrCodeOpen] = useState(false);
  const [isRandomPageEnabled, setIsRandomPageEnabled] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isSearchBarVisible, setIsSearchBarVisible] = useState(false);

  const categories = useMemo(() => config.dataNames[0].category || [], []);

  const location = useLocation();
  const aiParam = (new URLSearchParams(location.search)).get('aiLangs');

  const [showFirstVisitModal, setShowFirstVisitModal] = useState(localStorage.getItem('selectedLangs') === null);
  const [selectedAiLangs, setSelectedAiLangs] = useState(() => {
    if (aiParam) {
      return aiParam.split(',');
    }
    const storedLangs = localStorage.getItem('selectedLangs');
    return storedLangs ? storedLangs.split(',') : [config.defaultSecondLanguage];
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [sortField, setSortField] = useState('videoDate');
  const [sortOrder, setSortOrder] = useState('desc');

  const initialSearchTermRef = useRef(null);
  const initialSearchIdRef = useRef(null);

  const hasTopics = config.dataNames[0].topics !== '' ? true : false;

  const randomOffset = useMemo(() => {
    return isRandomPageEnabled ? Math.floor(Math.random() * 10) : 0;
  }, [isRandomPageEnabled]);

  const handleRandomPageToggle = (event) => {
    setIsRandomPageEnabled(event.target.checked);
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const subjectsCountData = await fetchSubjectsCount({ categories: selectedCategories });
      setSubjectsCount(subjectsCountData);

      const totalPages = Math.ceil(subjectsCountData / rowsPerPage);
      const actualPage = (page + randomOffset) % totalPages;
      
      const latestSubjectsData = await fetchLatestSubjects({
        page: actualPage,
        rowsPerPage,
        sort: sortField,
        order: sortOrder,
        aiLangs: selectedAiLangs,
        categories: selectedCategories
      });
      const topicsData = await fetchTopics();

      setLatestSubjects(latestSubjectsData);
      setSubjectsCount(subjectsCountData);
      setTopics(topicsData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [page, rowsPerPage, sortField, sortOrder, selectedAiLangs, randomOffset, selectedCategories]);

  useEffect(() => {
    fetchData();

    const params = new URLSearchParams(location.search);
    const idParam = params.get('id');
    const subjectParam = params.get('subject');
    const langParam = params.get('lang');

    if (idParam) {
      initialSearchIdRef.current = idParam;
    }
    if (subjectParam) {
      initialSearchTermRef.current = subjectParam;      
    }
    if (langParam) {
      i18n.changeLanguage(langParam);
    }

  }, [fetchData, location.search, i18n]);

  useEffect(() => {
    if (initialSearchTermRef.current || initialSearchIdRef.current) {
      handleSearch(initialSearchTermRef.current, initialSearchIdRef.current);
    }
  }, []);

  const handleSearch = async (searchTerm, searchId, type) => {
    if (!searchTerm?.trim() && !searchId?.trim()) {
      setSearchResults(null);
      setIsSearching(false);
      return;
    }
    setIsSearching(true);
    try {
      let results;
      if (searchId) {
        results = await searchSubjectById(searchId, selectedAiLangs);
        if (results) {
          results = [results];
        }
      } else if (searchType === 'prefix') {
        results = await searchSubjects(searchTerm, selectedAiLangs);
      } else {
        results = await searchSubjectsByKeywords(searchTerm, selectedAiLangs);
      }
      setSearchWords(searchTerm);
      setSearchResults(results);
    } catch (error) {
      console.error('Error searching subjects:', error);
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  };

  const handleSearchTypeChange = (newType) => {
    setSearchType(newType);
  };

  const handleClearSearch = () => {
    setSearchResults(null);
    setIsSearching(false);
    initialSearchTermRef.current = null;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setVideoIndex(0);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleToggleTvMode = () => {
    setTvMode(!tvMode);
  };

  const hasNextPage = (page + 1) * rowsPerPage < subjectsCount;

  const handleNextPage = useCallback(() => {
    setPage((prevPage) => prevPage + 1);
    setVideoIndex(0);
  }, []);

  const handleSortChange = (newSortField, newSortOrder) => {
    setSortField(newSortField);
    setSortOrder(newSortOrder);
  };

  const subjectsToDisplay = searchResults !== null ? searchResults : latestSubjects;

  const handleQrCodeOpen = () => setQrCodeOpen(true);
  const handleQrCodeClose = () => setQrCodeOpen(false);

  const handleCategoryChange = (event) => {
    setSelectedCategories(event.target.value);
  };

  const handleFirstVisitLanguageSelect = (langs) => {
    setSelectedAiLangs(langs);
    localStorage.setItem('selectedLangs', langs.join(','));
  };

  const toggleSearchBar = () => {
    setIsSearchBarVisible(!isSearchBarVisible);
  };

  return (
    <Container>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0 }}>
        <IconButton 
          component="a" 
          href="http://profoundwords.us" 
          aria-label="home"
        >
          <img src={mainLogo} alt="Logo" style={{ height: 40, marginRight: 16 }} />
        </IconButton> 
        <Box className="language-switcher">
          <LanguageSwitcher />
          <IconButton onClick={handleQrCodeOpen} aria-label="QR Code">
            <QrCodeIcon />
          </IconButton>
          <FirstVisitModal
            open={showFirstVisitModal}
            onClose={() => setShowFirstVisitModal(false)}
            onLanguageSelect={handleFirstVisitLanguageSelect}
          />
          <AiLangs2 
            selectedLangs={selectedAiLangs}
            onLanguageChange={(langs) => {
              setSelectedAiLangs(langs);
              localStorage.setItem('selectedLangs', langs);
            }}
          />
          <IconButton 
            component="a" 
            href={`/print?lang=${i18n.language}`}
            aria-label="Print"
            target='_blank'
            style={{color: '#219ebc'}}
          >
            <Print />
            {/* <p style={{fontSize: '10px'}}><b>Quick All</b></p> */}
          </IconButton>
          {config.downloadFolder && (
            <IconButton
              component="a"
              href={config.downloadFolder}
              aria-label="Download"
              target="_blank"
              rel="noopener noreferrer"
              style={{color: '#4caf50'}}
            >
              <GetAppIcon />
            </IconButton>
          )}
          <IconButton onClick={toggleSearchBar} aria-label="Toggle Search">
            <SearchIcon />
          </IconButton>
        </Box>
        <QRCodeModal open={qrCodeOpen} onClose={handleQrCodeClose} />
      </Box>
      <Box sx={{ textAlign: 'center', mb: 2 }}>
        <Typography 
          variant={isMobile ? "h6" : "h4"} // Change variant based on screen size
          className="welcome-title"
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} // Flexbox for vertical alignment
        >
          <Link underline='none' href='/'>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src={logo} alt="Logo" style={{ height: 52, marginTop: 0, marginRight: 8 }} />
              <i>
                <font style={{ fontWeight: 'bold', color: '#03487d' }}>
                  {t('welcome')}
                </font>
              </i>
            </Box>
          </Link>
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
        {isSearchBarVisible ? (
          <SearchBar
            onSearch={(term) => handleSearch(term, null, searchType)}
            onClear={handleClearSearch}
            initValue={initialSearchTermRef.current || ''}
            searchType={searchType}
            onSearchTypeChange={handleSearchTypeChange}
          />
        ) : null}
      </Box>
      <Box sx={{ mt: 2, mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <LiveTvIcon sx={{ paddingRight: '8px', color: 'red'}}/>
          <FormControlLabel
            control={<Switch checked={tvMode} onChange={handleToggleTvMode} />}
            label={t('tv')}
            sx={{
              '& .MuiFormControlLabel-label': {
                color: 'blue',
              },
            }}
          />
        </Box>
        {categories.length > 0 && (
          <Box sx={{ flexGrow: 1, maxWidth: '50%', ml: 2 }}>
            <SelectCategory
              categories={categories}
              selectedCategories={selectedCategories}
              onChange={handleCategoryChange}
            />
          </Box>
        )}
      </Box>
      {!isLoading && tvMode && (
        <TVModePanel 
          key={page}
          subjects={subjectsToDisplay} 
          initIndex={videoIndex}
          hasNextPage={hasNextPage}
          onNextPage={handleNextPage}
        />        
      )}    
      {isLoading ? (
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <CircularProgress />
          </Grid>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <>
          <hr />
          {hasTopics && <TopicsGrid topics={topics} />}
          {isSearching ? (
            <Box display="flex" justifyContent="center" mt={4}>
              <CircularProgress />
            </Box>
          ) : searchResults !== null ? (
            <Box>
              <Typography className="section-header">
                {t('searchResults')}
                <Link underline='none' href='/' alt='Home'>
                  <HomeIcon style={{marginLeft: '10px', color: '#fb6f92', fontSize: 'small'}} />
                </Link>
              </Typography>
              {searchResults.length > 0 ? (
                <SubjectsTable 
                  subjects={searchResults} 
                  searchTerm={searchWords}
                  searchType={searchType}
                />
              ) : (
                <Alert severity="info">{t('noResults')}</Alert>
              )}
            </Box>
          ) : (
            <Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography className="section-header">
                  {t('latestSubjects')}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isRandomPageEnabled}
                        onChange={handleRandomPageToggle}
                        size="small"
                      />
                    }
                    label={
                      <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
                        Enable Random Page (Offset Pages: {(page + randomOffset) % Math.ceil(subjectsCount / rowsPerPage) + 1})
                      </Typography>
                    }
                  />
                </Box> 
              </Box>
              <SubjectsTable
                subjects={latestSubjects}
                page={page}
                rowsPerPage={rowsPerPage}
                totalCount={subjectsCount}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onSortChange={handleSortChange}
                sortField={sortField}
                sortOrder={sortOrder}
              />
            </Box>
          )}
        </>
      )}
      <div>
        {searchResults === null && <TopSubjects selectedAiLangs={selectedAiLangs} />}
      </div>  
      <GoogleAd />
      <Footer />
    </Container>
  );
};

export default WelcomePage;