import axios from 'axios';
import config from '../config';

const { apiUrl, routeNames } = config;

export const fetchLatestSubjects = async ({
  page,
  rowsPerPage,
  sort = 'videoDate',
  order = 'desc',
  aiLangs = '',
  categories = []
}) => {
  try {
    const queryParams = new URLSearchParams();

    if (aiLangs !== '') {
      queryParams.append('langs', aiLangs);
    }

    queryParams.append('sort', sort);
    queryParams.append('order', order);
    queryParams.append('limit', rowsPerPage.toString());
    queryParams.append('offset', (page * rowsPerPage).toString());

    // Add multiple category values
    categories.forEach(category => {
      queryParams.append('category', category);
    });

    const response = await axios.get(`${apiUrl}/${routeNames.subjects}?${queryParams.toString()}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching latest subjects:', error);
    throw error;
  }
};

export const fetchTopSubjects = async ({page, rowsPerPage = 12, aiLangs = ''}) => {
  try {
    const queryAiLangs = aiLangs !== '' ? `langs=${aiLangs}&` : '';
    const offset = page * rowsPerPage;
    const response = await axios.get(`${apiUrl}/${routeNames.subjects}/top?${queryAiLangs}limit=${rowsPerPage}&offset=${offset}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching top subjects:', error);
    throw error;
  }
};

export const fetchSubjectsCount = async ({ categories = [], markets = [] } = {}) => {
  try {
    const queryParams = new URLSearchParams();

    // Add multiple category values
    categories.forEach(category => {
      queryParams.append('category', category);
    });

    // Add multiple market values (in case you want to filter by markets as well)
    markets.forEach(market => {
      queryParams.append('market', market);
    });

    const queryString = queryParams.toString();
    const url = `${apiUrl}/${routeNames.subjects}/count${queryString ? `?${queryString}` : ''}`;

    const response = await axios.get(url);
    return response.data.count;
  } catch (error) {
    console.error('Error fetching subjects count:', error);
    throw error;
  }
};

export const fetchTopics = async () => {
  try {
    if (config.dataNames[0].topics === '') {
      return [];
    }
    const response = await axios.get(`${apiUrl}/${routeNames.topics}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching topics:', error);
    throw error;
  }
};

export const fetchSubjectsCountByTopic = async (topicParam) => {
  try {
    const response = await axios.get(`${apiUrl}/${routeNames.topics}/count?id=${topicParam}`);
    return response.data.count;
  } catch (error) {
    console.error('Error fetching subjects count by topic:', error);
  }
}; 

export const fetchSubjectsByTopic = async (page, rowsPerPage, topicParam) => {
    try {
      const offset = page * rowsPerPage;
      const response = await axios.get(`${apiUrl}/${routeNames.topics}/${topicParam}?sort=videoDate&limit=${rowsPerPage}&offset=${offset}`);
      return response.data;
    } catch (error) {
      console.error('Error fetching latest subjects:', error);
      throw error;
    }
  };

export const searchSubjectById = async (id, aiLangs = '') => {
  try {
    const queryAiLangs = aiLangs !== '' ? `?langs=${aiLangs}` : '';
    const response = await axios.get(`${apiUrl}/${routeNames.subjects}/${id}${queryAiLangs}`);
    return response.data;
  } catch (error) {
    console.error('Error searching subjects:', error);
    throw error;
  }
};  

export const searchSubjects = async (searchTerm, aiLangs = '') => {
  try {
    const queryAiLangs = aiLangs !== '' ? `&langs=${aiLangs}` : '';
    const response = await axios.get(`${apiUrl}/${routeNames.subjects}/search-prefix?keyword=${searchTerm}${queryAiLangs}`);
    return response.data;
  } catch (error) {
    console.error('Error searching subjects:', error);
    throw error;
  }
};

export const searchSubjectsByKeywords = async (searchTerm, aiLangs = '') => {
  try {
    const queryAiLangs = aiLangs !== '' ? `&langs=${aiLangs}` : '';
    const response = await axios.get(`${apiUrl}/${routeNames.subjects}/search-keywords?keyword=${searchTerm}${queryAiLangs}`);
    return response.data;
  } catch (error) {
    console.error('Error searching subjects:', error);
    throw error;
  }
};

export const searchSubjectsByTopic = async (searchTerm, topicParam) => {
    try {
      const response = await axios.get(`${apiUrl}/${routeNames.topics}/search-prefix?id=${topicParam}&keyword=${searchTerm}`);
      return response.data;
    } catch (error) {
      console.error('Error searching subjects:', error);
      throw error;
    }
  };
  
export const searchSubjectsByTopicByKeywords = async (searchTerm, topicParam) => {
    try {
      const response = await axios.get(`${apiUrl}/${routeNames.topics}/search-keywords?id=${topicParam}&keyword=${searchTerm}`);
      return response.data;
    } catch (error) {
      console.error('Error searching subjects:', error);
      throw error;
    }
  };  

export const getShareUrl = (subject, language, aiLangs) => {
    return `/?subject=${encodeURIComponent(subject)}&lang=${language}${aiLangs.length > 0 ? `&aiLangs=${aiLangs}` : ''}`;
};