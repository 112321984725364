const config = {
    apiUrl: 'https://bloomberg-backend-dot-bloomberg-deploy.uw.r.appspot.com',
    defaultLimit: 10,
    defaultOffset: 0,
    routeNames: {
        subjects: 'subjects',
        topics: 'topics'
    },
    dataNames: [
        {
            subjects: 'phrases',
            subject: 'phrase',
            topics: '',
            category: [
                "Advanced Market Concept",
                "Key Economic Indicator",
                "Complex Financial Instrument",
                "Critical Business Strategy",
                "Emerging Market Trend",
                "Significant Regulatory Development",
                "Monetary Policy",
                "Fiscal Policy",
                "Global Trade Dynamics",
                "Corporate Finance",
                "Investment Strategy",
                "Risk Management",
                "Technological Disruption in Finance",
                "Sustainable Finance",
                "Macroeconomic Analysis"
            ],
            isSubjectToCount: "yes",
            topicsSentiments: ['positive'],
            aiFields: {
                category: "category",
                significance: "significance",
                explanation: "explanation",
                sentenceExample: "sentenceExample",
                translations: "translations",
                similarPhrases: "similarPhrases",
            },
            aiLanguages: [
                { code: 'zh', name: 'Chinese', word: '中文' },
                { code: 'es', name: 'Spanish', word: 'Español' },
            ]    
        }
    ],
    defaultSecondLanguage: 'zh',
    languages: [
        { code: 'en', name: 'English' },
        // { code: 'zh', name: '中文' }
    ],
    videoWatchSeconds: 20,
    downloadFolder: 'https://drive.google.com/drive/folders/1Reu7k1jeZWCixAMn4O9LAQhdT3NzlaRD?usp=drive_link'
};
  
export default config;