import React from 'react';
import { Modal, Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import qrCodeImage from '../../qrcode.png';
import logo from '../../logo256.png';

const QRCodeModal = ({ open, onClose }) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="qr-code-modal"
      aria-describedby="qr-code-description"
    >
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        maxWidth: '90%',
        maxHeight: '90%',
        overflow: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <img src={logo} alt="Logo" style={{ height: 52, marginRight: 16 }} />
          <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', color: '#03487d' }}>
            {t('welcome')}
          </Typography>
        </Box>
        <img src={qrCodeImage} alt="QR Code" style={{ maxWidth: '100%', height: 'auto', display: 'block' }} />
      </Box>
    </Modal>
  );
};

export default QRCodeModal;