import React, { useState, useEffect } from 'react';
import { Box, Menu, MenuItem, IconButton, Checkbox, ListItemText } from '@mui/material';
import { useLocation } from 'react-router-dom';
import LanguageIcon from '@mui/icons-material/Language';
import config from '../config';

const languages = config.dataNames[0].aiLanguages;

const AiLangs2 = ({ selectedLangs, onLanguageChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (langCode) => {
    const newSelectedLangs = selectedLangs.includes(langCode)
      ? selectedLangs.filter(l => l !== langCode)
      : [...selectedLangs, langCode];
    onLanguageChange(newSelectedLangs);
  };

  return (
    <Box>
      <IconButton onClick={handleClick}>
        <LanguageIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {languages.map((lang) => (
          <MenuItem key={lang.code} onClick={() => handleLanguageChange(lang.code)}>
            <Checkbox checked={selectedLangs.includes(lang.code)} />
            <ListItemText primary={lang.word} secondary={lang.name} />
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default AiLangs2;